@use '@styles/variables' as *;

.travel-expert-card-container {
  align-items: center;
  border-radius: 8px;
  border: 1px solid $v2-neutral-200;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  margin-top: 20px;

  @media (min-width: $breakpoint-lg) {
    margin-bottom: 16px;
  }

  .advisor-card__avatar {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 88px;
    margin-left: 16px;
    width: 88px;
    aspect-ratio: 1 / 1;
    @media (min-width: $breakpoint-md) {
      height: 120px;
      width: 120px;
    }
  }

  .advisor-card__content {
    padding: 16px 16px 16px 0;
  }

  .advisor-card__rate {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;

    .advisor-card__rate-value {
      color: $v2-neutral-800;
      font-family: $font-texts;
      font-size: 18px;
      font-weight: 600;
      position: relative;
      top: 1px;
    }
  }

  .advisor-card__name {
    color: $v2-neutral-800;
    font-family: $font-texts;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
  }

  .advisor-card__country {
    color: $v2-neutral-500;
    font-family: $font-texts;
    font-size: 0.875rem; // 14px
    font-weight: 400;
    margin: 0;

    @media (min-width: $breakpoint-md) {
      font-weight: 400;
    }
  }

  .advisor-card__info {
    display: grid;
    margin-top: 16px;
  }

  .advisor-card__idioms {
    margin-bottom: 12px;

    .advisor-card__idioms-text {
      color: $v2-neutral-800;
      font-family: $font-texts;
      font-size: 0.75rem; // 12px
      font-weight: 600;
      display: block;
      margin-bottom: 8px;
    }

    .advisor-card__idioms-options {
      display: flex;
      flex-flow: row wrap;
      gap: 4px;

      img {
        width: 20px;
        height: 16px;
      }
    }
  }

  .advisor-card__price {
    margin-top: 16px;
    display: flex;
    flex-flow: column wrap;

    .advisor-card__price-value {
      color: $v2-primary-600;
      font-family: $font-texts;
      font-size: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 700;
      display: block;
    }

    .advisor-card__price-text {
      color: $v2-neutral-500;
      display: block;
      font-family: $font-texts;
      font-size: 0.75rem; // 12px
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 400;
      margin-bottom: 8px;
      width: 100%;
    }
  }
}
