@use '@styles/variables' as *;

.travel-expert-details {
  margin: 0 auto;
  max-width: $container-size;

  .ant-breadcrumb {
    margin-top: 20px;
    padding: 0 20px;

    @media (min-width: $breakpoint-lg) {
      padding: 0;
    }
  }

  .mentor-details-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 24px;
    max-width: 100vw;
    padding: 0 20px;
    width: 100%;

    @media (min-width: $breakpoint-lg) {
      padding: 0;
    }

    @media (min-width: $breakpoint-lg) {
      grid-template-columns: 1fr 2fr;
      gap: 16px;
    }

    .mentor-details__cta {
      margin-top: 20px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }



}
