@use "@styles/variables" as *;

.advisor-mobile-video-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 9 / 16;
  margin: 16px auto;
  overflow: hidden;
  border-radius: 12px;
  height: 180px;


  .advisor-mobile-video-blur-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px);
    transform: scale(1.1);
    z-index: 0;
  }

  .advisor-mobile-video {
    background-color: transparent;
    border-radius: 12px;
    display: block;
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: auto;
    z-index: 1;
  }

  /* THUMBNAIL + PLAY BUTTON */
  .advisor-mobile-video-thumbnail {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }
  }

  .advisor-mobile-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  .mentor-video-play-bg {
    background-color: $v2-background-color;
    border-radius: 50%;
    position: absolute;
    top: 40%;
    left: 45%;
    width: 48px;
    height: 48px;
    z-index: 2;
  }

  .advisor-mobile-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    background-color: $v2-secondary-500;
    mask: url('data:image/svg+xml;utf8,<svg fill="black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/></svg>') no-repeat center;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg fill="black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/></svg>') no-repeat center;
    mask-size: contain;
    -webkit-mask-size: contain;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}
