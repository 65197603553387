@use "@styles/variables" as *;

.motivate-to-be-expert {

  .mentor-video-wrapper {
    aspect-ratio: 9 / 16;
    border-radius: 12px;
    max-height: 180px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      max-height: 365px;
    }
  }

  .mentor-video-blur-bg {
    filter: blur(20px);
    height: 100%;
    object-fit: cover;
    position: absolute;
    transform: scale(1.1);
    width: 100%;
    z-index: 0;
  }

  .mentor-video {
    background-color: transparent;
    border-radius: 0;
    display: block;
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: auto;
    z-index: 1;
  }

  /* THUMB + botão de play */
  .mentor-video-thumbnail {
    cursor: pointer;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;

    img {
      border-radius: 12px;
      height: 100%;
      object-fit: fill;
      width: 100%;
      z-index: 1;
    }
  }

  .mentor-video-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .mentor-video-play-bg {
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 45%;
    left: 50%;
    width: 48px;
    height: 48px;
    z-index: 2;
  }

  .mentor-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    background-color: $v2-secondary-500;
    mask: url('data:image/svg+xml;utf8,<svg fill="black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/></svg>') no-repeat center;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg fill="black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/></svg>') no-repeat center;
    mask-size: contain;
    -webkit-mask-size: contain;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}
